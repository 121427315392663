@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.App {
  text-align: center;
  background-color: #E8DCCD;
  /* min-height: 100vh; */
  font-family: 'poppins';
}

.header {
  text-align: center;
  background-color: #E8DCCD;
  /* min-height: 20vh;  */
  padding: 8px 64px;
}

.header>img{
  max-width: 50%;
}

.horizontal{
 min-width: 100px;
 min-height: 1px;
 background-color: #BF3430;
 margin: 0 64px;
}

.tagline{
  padding: 16px;
  color: #BF3430;
  font-family: 'poppins' ;
  font-size: 40px;
}

.bold_tagline{
  font-weight: 700;
}

.main{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 16px 100px;
  background-color: #E8DCCD;
  margin-bottom: 40px;
}

.left{
  min-width: 65%;
  /* min-height: 420px; */
  background-color: #E8DCCD;  
}

.right{
  min-width: 25%;
  /* min-height: 420px; */
  background-color: #E8DCCD; 
}

.left_box{
  color: #EADACA;
  font-family: 'poppins';
  width: calc(100% - 80px);
  /* background-image: url(./Rectangle2.png); */
  background-image: url(./rectangle2mobile.png);
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 30px;
  padding: 64px 40px;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 24px;
}

.left_box_heading{
  font-size: 56px;
  font-weight: 200;
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.left_box_heading>div{
  line-height: 1;
}

.left_bold{
  font-weight: 600;
}

.left_box_line{
  height: 1px;
  background-color: #E8DCCD;
}

.left_box_text{
  font-size: 20px;
}



.right_box_upper{
  color: #EADACA;
  font-family: 'poppins';
  /* min-width: 350px; */
  width: calc(100%-40px);
  padding: 20px;
  margin-bottom: 20px;
  min-height: 150px;
  background-color: #BF3430;
  border-radius: 25px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right_box_upper>img{
  object-fit: cover;
  max-width: 100%;
}

.right_box_lower{
  color: #B51515;
  font-family: 'poppins';
  width: calc(100%-40px);
  padding: 20px;
  min-height: 150px;
  background-color: #FFFFFF;
  border-radius: 25px;
  display: flex;
  /* flex-direction: column; */
  justify-content: flex-start;
  align-items: flex-end;
}

.icons{
  text-align: left;
  display: flex;
  gap: 8px;
}

.contact_text{
  text-align: left;
}
              
.footer{
  min-height: 120px; 
  background-color: #BF3430;
  padding: 16px 64px;
}   

.footer_main{
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  justify-content: space-evenly;
  gap: 16px;
  color: #E8DCCD;
  font-family: 'poppins';
  min-height: 120px;
  padding-bottom: 15px;
}

.footer_legal{
  color: #E8DCCD;
  font-family: 'poppins';
}


.footer_hr{
  background-color: #E8DCCD;
  /* min-width: 500px; */
  /* max-width: 1200px; */
  min-height: 1px;
  /* margin-left: 40px; */
  /* margin-right: 40px; */
  margin-bottom: 10px;
} 

.footer_child{
  text-align: left;
  font-size: 14px;
}

.footer_child_img{
  text-align: left;
}

.mobile_br{
  display: none;
}

.footer_icon{
  font-size: 32px;
}

.footer_child_contact{
  display: flex;
  gap: 8px;
  justify-content: center;
  align-self: end;
}

@media only screen and (max-width: 860px){
  .left_box_heading{
    font-size: 40px;
  }
  .footer_main{
    grid-template-columns: auto;
    gap: 28px;
    justify-content: stretch;
  }
}

@media only screen and (max-width: 780px){
  .main{
    padding: 16px 64px;
  }
}

@media only screen and (orientation: portrait){
  .header{
    padding: 20px 64px 12px;
  }
  .header>img{
    max-width: 80%;
  }

  .horizontal{
    margin: 0 24px;
  }

  .tagline{
    font-size: 22px;
    padding: 12px 24px 24px;
  }

  .main{
    flex-direction: column;
    padding: 16px 24px;
    gap: 12px;
    align-items: stretch;
    margin-bottom: 24px;
  }

  .left_box{
    padding: 40px 20px;
    width: calc(100% - 40px);
    /* background-image: url(./rectangle2mobile.png); */
    background-position: left;
  }

  .left_box_heading{
    font-size: 32px;
  }

  .left_box_text{
    font-size: 14px;
  }

  .right_box_lower{
    min-height: max-content;
  }

  .right_box_lower_content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .footer{
    padding: 20px 24px;
  }

  .footer_main{
    grid-template-columns: auto;
    gap: 28px;
    justify-content: stretch;
  }

  .footer_child_img{
    place-self: start;
  }

  .footer_child_contact{
    /* align-self: center */
  }

  .mobile_br{
    display: block;
  }
  .mobile_br_hide{
    display: none;
  }
}